<template>
  <section id="report">
    <b-breadcrumb
      :items="breadcrumb"
    />

    <b-card-actions
      ref="filters"
      title="Filtros"
      class="form-filters mt-1"
      :action-close="false"
      :action-refresh="false"
      :action-collapse="true"
    >
      <b-row>
        <b-col cols="3">
          <b-form-group
            label="Denunciante"
            label-for="reporter_id"
          >
            <b-form-input
              id="reporter_id"
              v-model="filtersValues.reporter_id"
              placeholder="ID do usuário"
              @keyup.enter="getReports()"
            />
          </b-form-group>
        </b-col>

        <b-col cols="3">
          <b-form-group
            label="Denunciado"
            label-for="user_id"
          >
            <b-form-input
              id="user_id"
              v-model="filtersValues.user_id"
              placeholder="ID do usuário"
              @keyup.enter="getReports()"
            />
          </b-form-group>
        </b-col>

        <b-col cols="3">
          <b-form-group
            label="Motivo"
            label-for="reason"
          >
            <b-form-input
              id="reason"
              v-model="filtersValues.reason"
              placeholder=""
              @keyup.enter="getReports()"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="3"
          class="form-buttons"
        >
          <b-form-group
            label=""
            label-for="btn-buscar"
            style="margin-top: 20px"
          >
            <b-button
              id="btn-buscar"
              variant="primary"
              @click="getReports()"
            >
              Buscar
            </b-button>

            <b-button
              id="btn-limpar"
              variant="outline-secondary"
              @click="reset()"
            >
              Limpar
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-actions>

    <b-card
      ref="results"
      title="Resultados"
    >
      <b-row>
        <b-col cols="12">
          <b-table
            fixed
            class="table-responsive-xl"
            :busy="isLoading"
            :items="results"
            :fields="[
              { key: 'date', sortable: true, thStyle: 'vertical-align: middle; width: 200px;' },
              { key: 'reporter', sortable: false, thStyle: 'vertical-align: middle;', tdClass: 'text-break' },
              { key: 'user', sortable: false, thStyle: 'vertical-align: middle;', tdClass: 'text-break' },
              { key: 'actions', sortable: false, thStyle: 'vertical-align: middle; width: 110px;' },
            ]"
            sort-icon-left
            no-local-sorting
            show-empty
            :sort-by.sync="filtersValues.sortBy"
            :sort-desc.sync="filtersValues.sortDesc"
            @sort-changed="sortingChanged"
          >
            <template #empty>
              <h4>Nenhuma denúncia encontrada</h4>
            </template>

            <template #table-busy>
              <div class="text-center table-spinner">
                <b-spinner label="Loading..." />
                <strong>Carregando...</strong>
              </div>
            </template>

            <template #head(reporter)>
              <div class="text-nowrap">
                Denunciante
              </div>
            </template>

            <template #head(user)>
              <div class="text-nowrap">
                Denunciado
              </div>
            </template>

            <template #head(date)>
              <div class="text-nowrap">
                Data
              </div>
            </template>

            <template #head(actions)>
              <div class="text-nowrap">
                Ações
              </div>
            </template>

            <template #cell(reporter)="row">
              <div
                class="d-flex align-items-start justify-content-start"
              >
                <b-avatar
                  size="40"
                  :src="row.item.reporter.avatar ? row.item.reporter.avatar.sm : ''"
                  variant="light-primary"
                  class="mr-1"
                >
                  <feather-icon
                    v-if="!row.item.reporter.name"
                    icon="UserIcon"
                    size="22"
                  />
                </b-avatar>
                <div>
                  ({{ row.item.reporter.id }})<br>
                  {{ row.item.reporter.name }}
                </div>
              </div>
            </template>

            <template #cell(user)="row">
              <div
                class="d-flex align-items-start justify-content-start"
              >
                <b-avatar
                  size="40"
                  :src="row.item.user.avatar ? row.item.user.avatar.sm : ''"
                  variant="light-primary"
                  class="mr-1"
                >
                  <feather-icon
                    v-if="!row.item.user.name"
                    icon="UserIcon"
                    size="22"
                  />
                </b-avatar>
                <div>
                  ({{ row.item.user.id }})<br>
                  {{ row.item.user.name }}
                </div>
              </div>
            </template>

            <template #cell(date)="row">
              {{ formatDateTimeDbToView(row.item.created_at) }}
            </template>

            <template #cell(actions)="row">
              <b-button
                variant="primary"
                size="sm"
                @click="row.toggleDetails"
              >
                <feather-icon
                  icon="ChevronDownIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </b-button>
            </template>

            <template #row-details="row">
              <p>{{ row.item.reason }}</p>
            </template>

          </b-table>
        </b-col>
      </b-row>

      <b-row v-if="results.length > 0">
        <b-col cols="6">
          <p>Mostrando {{ pages.from }} até {{ pages.to }} de {{ pages.total }} resultados</p>
        </b-col>

        <b-col cols="6">
          <!-- pagination nav -->
          <b-pagination-nav
            v-model="currentPage"
            :number-of-pages="pages.last_page === undefined ? 99 : pages.last_page"
            base-url="#"
            align="end"
          />
        </b-col>
      </b-row>
    </b-card>
  </section>
</template>

<script>
import {
  BAvatar,
  BBreadcrumb,
  BButton,
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BPaginationNav,
  BRow,
  BSpinner,
  BTable,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { formatDateTimeDbToView } from '@/utils/helpers'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ReportService from '@/services/reportService'

const components = {
  BAvatar,
  BBreadcrumb,
  BButton,
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BPaginationNav,
  BRow,
  BSpinner,
  BTable,
  BCardActions,
}

export default {
  components,
  data() {
    return {
      breadcrumb: [
        {
          text: 'Dashboard',
          to: { name: 'cms-dashboard' },
        },
        {
          text: 'Ferramentas',
          active: true,
        },
        {
          text: 'Denúncias',
          active: true,
        },
      ],

      filtersInitialValues: {},
      filtersValues: {
        reporter_id: '',
        user_id: '',
        reason: '',
        sortBy: 'id',
        sortDesc: true,
      },

      pages: {},
      currentPage: 1,
      results: [],
      formatDateTimeDbToView,
      isLoading: false,
    }
  },

  watch: {
    currentPage() {
      this.getReports()
    },
  },

  created() {
    this.filtersInitialValues = { ...this.filtersValues }
    this.getReports()
  },

  methods: {
    getReports() {
      this.isLoading = true
      ReportService.getReports({
        ...this.filtersValues,
        page: this.currentPage,
      }).then(response => {
        if (response.data !== null) {
          this.pages = response.data.data.reports

          const { data } = this.pages
          this.results = data

          delete this.pages.data
        }
        this.isLoading = false
      }).catch(response => {
        if (response.response.data.errors) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Ops!',
              icon: 'BellIcon',
              variant: 'danger',
              text: response.response.data.errors.join('\n'),
            },
          })
        }
      })
    },

    sortingChanged(ctx) {
      this.filtersValues.sortBy = ctx.sortBy
      this.filtersValues.sortDesc = ctx.sortDesc
      this.getReports()
    },

    reset() {
      this.filtersValues = JSON.parse(JSON.stringify(this.filtersInitialValues))
      this.currentPage = 1
      this.getReports()
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
